import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  SET_ENTITY,
  CLEAR_ENTITY,
  SET_ROUTE,
  PUSH_ROUTE,
  CLEAR_ROUTE,
  SET_MAINTENANCE_MODE,
  SET_IFRAME,
  GET_TENANT,
  SET_CONFIRMATION_MODAL,
} from '@/shared/store/actionType';

export default function useSystem({ store = useStore() } = {}) {
  const iframe = computed(() => store.state.system.iframe);
  const isDemo = computed(() => store.getters['system/isDemo']);
  const entity = computed(() => store.state.system.entity);
  const route = computed(() => store.state.system.route);
  const maintenanceMode = computed(() => store.state.system.maintenance_mode);
  const tenant = computed(() => store.state.system.tenant);
  const analysisId = computed(() => {
    const analysisConfigs = store.state.system.tenant?.data?.analysis_configs ?? [];
    const salesRepConfig = analysisConfigs.find(config => config.name === 'SALESREP');
    return salesRepConfig?.analysis_field_id ?? null;
  });

  const setEntity = (entity) => store.dispatch(`system/${SET_ENTITY}`, entity);
  const clearEntity = () => store.dispatch(`system/${CLEAR_ENTITY}`);
  const setRoute = (route) => store.dispatch(`system/${SET_ROUTE}`, route);
  const pushRoute = ({ defaultUrl, success }) => store.dispatch(`system/${PUSH_ROUTE}`, { defaultUrl, success });
  const clearRoute = () => store.dispatch(`system/${CLEAR_ROUTE}`);
  const setMaintenanceMode = (enableMaintenance) => store.dispatch(`system/${SET_MAINTENANCE_MODE}`, enableMaintenance);
  const setIframe = (enableIframe) => store.dispatch(`system/${SET_IFRAME}`, enableIframe);
  const getTenant = () => store.dispatch(`system/${GET_TENANT}`);
  const showConfirmationModal = params => store.dispatch(`confirmationModal/${SET_CONFIRMATION_MODAL}`, params);

  return {
    iframe,
    isDemo,
    entity,
    route,
    maintenanceMode,
    tenant,
    setEntity,
    clearEntity,
    setRoute,
    pushRoute,
    clearRoute,
    setMaintenanceMode,
    setIframe,
    getTenant,
    showConfirmationModal,
    analysisId
  };
}