<template>
  <div class="body-wrapper">
    <AddInteractionEntity @valid="onEntityValid" />

    <AddInteractionFurtherDetails
      :interaction-id="interactionId"
      :reset-signal="resetTimescaleFormSignal"
      @valid="onDetailValid"
      @on-follow-up="onFollowUp"
    />
  </div>

  <div class="form-actions">
    <CustomButton
      v-scroll-to-top
      purpose="action"
      :disabled="!isValid"
      small
      @click="completeInteraction"
    >
      {{ buttonLabel }}
    </CustomButton>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { CustomButton } from '@sales-i/dsv3';
import AddInteractionEntity from '@/crm/components/Calendar/AddInteraction/AddInteractionEntity.vue';
import AddInteractionFurtherDetails from '@/crm/components/Calendar/AddInteraction/AddInteractionFurtherDetails.vue';
import { useRoute, } from 'vue-router';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { t } from '@sales-i/utils';
import { useStore } from 'vuex';
import { useUsers } from '@/shared/composables/useUsers';
import { useInteraction } from '@/crm/composables/useInteraction';
import useSystem from '@/shared/composables/useSystem';
import { SET_DATE } from '@/crm/store/actionType';
import { navigateToUrl } from 'single-spa';
import useFields from '@/shared/composables/useFields';

/* Setup
----------------------------------------------------------*/
const vroute = useRoute();
const store = useStore();
const { usersOptions } = useUsers(store);
const {
  selected,
  currentlyEditing,
  isBackdated,
  putInteraction,
  postInteraction,
  setFurtherDetails,
  clearInteractionsById,
  resetNonMandatoryFields,
} = useInteraction({ store, vroute });

const { pushRoute } = useSystem({ store });

const { 
  interactionOptionsFields, 
  interactionTypeOptions,
  interactionOutcomeOptions,
} = useFields({ store });

const buttonLabel = computed(() => {
  return props.isEditInteraction ? t('Save') : t('Add interaction');
});

// Props
const props = defineProps({
  interactionId: {
    type: String,
    default: '',
  },
  isEditInteraction: {
    type: Boolean,
    default: false,
  },
});

const followUp = ref(null);
const resetTimescaleFormSignal = ref(0);

/* Update the stage.
----------------------------------------------------------*/
const emit = defineEmits(['stageUpdate']);
onMounted(() => {
  emit('stageUpdate', 'Customer/Prospect');
});

/** Computed */
const isValid = computed(() => !!(
  currentlyEditing.value.furtherDetails.purpose &&
  currentlyEditing.value.furtherDetails.interactionType &&
  currentlyEditing.value.dateTime &&
  currentlyEditing.value.duration.hours >= 0 && 
  currentlyEditing.value.duration.minutes >= 0 &&
  currentlyEditing.value.entity.id
));

const firstColumn = computed(() => [purposeField.value, interactionNote.value]);
const secondColumn = computed(() => [interactionType.value, interactionOutcome.value]);
const thirdColumn = computed(() => [interactionActions.value, assigneesField.value]);
const columns = computed(() => [firstColumn.value, secondColumn.value, thirdColumn.value]);
const purposeField = computed(() => ({
  ...interactionOptionsFields.value[1],
  value: currentlyEditing.value.furtherDetails.purpose,
  valid: !!currentlyEditing.value.furtherDetails.purpose,
}));

const interactionNote = computed(() => {
  let field = {
    label: t('Interaction note'),
    id: 'interactionNote',
    type: 'textarea',
    errorMessage: t('Please enter an interaction note'),
    maxLength: 280,
  };
  if (currentlyEditing.value.furtherDetails.interactionNote) {
    field.value = currentlyEditing.value.furtherDetails.interactionNote;
  }
  return field;
});

const interactionType = computed(() => {
  let field = {
    label: t('Interaction type'),
    id: 'interactionType',
    type: 'select',
    options: interactionTypeOptions.value?.map(item => ({
      text: item.name,
      value: item.id,
    })),
    valid: true,
    errorMessage: t('Please select an interaction type'),
  };
  if (currentlyEditing.value.furtherDetails.interactionType) {
    field.value = currentlyEditing.value.furtherDetails.interactionType;
    field.valid = true;
  }
  return field;
});

const interactionOutcome = computed(() => {
  let field = {
    label: t('Interaction outcome'),
    id: 'outcome',
    type: 'select',
    options: interactionOutcomeOptions.value?.map(item => ({
      text: item.name,
      value: item.id,
    })),
    valid: true,
    errorMessage: t('Please select an outcome'),
  };
  if (currentlyEditing.value.furtherDetails.outcome) {
    field.value = currentlyEditing.value.furtherDetails.outcome;
    field.valid = true;
  }
  return field;
});

const interactionActions = computed(() => {
  // Filter getInteractionsOptionsFields array to get the nextAction options
  let options = interactionOptionsFields.value.filter(item => item.id === 'nextAction')[0].options;
  let field = {
    label: t('Next action'),
    id: 'nextAction',
    type: 'select',
    options,
    errorMessage: t('Please enter a next action'),
  };
  if (currentlyEditing.value.furtherDetails.nextAction) {
    field.value = currentlyEditing.value.furtherDetails.nextAction;
    field.valid = true;
  }
  return field;
});

const assigneesField = computed(() => {
  let field = {
    label: t('Assignee'),
    id: 'assignee',
    type: 'select',
    options: usersOptions,
    errorMessage: t('Please select colleagues'),
  };
  if (currentlyEditing.value.furtherDetails.assignee) {
    field.value = currentlyEditing.value.furtherDetails.assignee;
  }
  return field;
});

/** Functions */
function buildFurtherDetails() {
  return columns.value.flat().reduce((details, field) => {
    details[field.id] = field.value;
    return details;
  }, {});
}

function onFollowUp(data) {
  followUp.value = data;
}

async function completeInteraction() {
  let furtherDetails = buildFurtherDetails();
  await setFurtherDetails(furtherDetails);

  // If you're editing an existing interaction
  if (vroute.params.id) {
    await putInteraction({ id: vroute.params.id });
  } else {
    await postInteraction();
  }

  let { data, updated } = selected.value;
  
  if (data?.id && updated && !isBackdated.value) {
    // Redirect the user to the `success` URL if it exists in the system/route object in the store. Otherwise, redirect to the default URL.
    pushRoute({
      defaultUrl: `${baseUrl}/${interactionsArea}`,
      success: true,
    });
  }
  if (data?.id && updated && isBackdated.value) {
    navigateToUrl(`${baseUrl}/${interactionsArea}/${data.id}`);
  }
  if (followUp.value) {
    // Pass 'from' to the processFollowUp function in case if interactions is created from the customer page
    processFollowUp(vroute);
    return;
  }
  clearInteractionsById();
}

async function processFollowUp(from) {
  resetNonMandatoryFields();
  await store.dispatch(`crm/interactions/${SET_DATE}`, followUp.value);
  await postInteraction(); 
  followUp.value = null;
  if (from?.fullPath?.includes('/crm/customers')) {
    navigateToUrl(from.fullPath); // Should navigate back to Customer page
  } else {
    navigateToUrl(`${baseUrl}/${interactionsArea}`);
  }
  resetTimescaleFormSignal.value++;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.body-wrapper {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
.form-content {
  padding: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
}
.form-actions {
  background-color: var(--colour-panel-action);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: var(--spacing-3);
}
</style>