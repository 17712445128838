<template>
  <div class="email-threads">
    <div
      v-if="threads !== null && threads.length"
      class="scrollable"
    >
      <EmailThread
        v-for="(thread, index) in threads"
        :key="index"
        :model-value="thread"
        :selected="selectedId === thread?.thread_id"
        :icon-size="threadIconSize"
        :icon-color="threadIconColor"
        :icon-color-selected="threadIconColorSelected"
        @update:selected="handleSelected(thread)"
        @reply="replyToEmail"
      >
        <slot />
      </EmailThread>
    </div>
    <div
      v-else
      class="scrollable"
    >
      <p
        v-if="!loadingThread"
        class="no-emails"
      >
        {{ t('No recent email conversations') }}
      </p>
    </div>
    <CustomButton
      v-if="showViewMore"
      class="view-more-link"
      purpose="text"
      :label="t('View more')"
      small
      @click.stop="emit('viewMore')"
    />
    <CustomButton
      v-else
      class="p-2 pt-1"
      purpose="text"
      :label="t('Reload')"
      small
      @click.stop="emit('reload')"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import { CustomButton,  } from '@sales-i/dsv3';
import EmailThread from './EmailThread.vue';

const props = defineProps({
  threads: {
    type: Array,
    default: () => [],
  },
  selectedId: {
    type: String,
    default: undefined,
  },
  loadingThread: {
    type: Boolean,
    default: false,
  },
  limit: {
    type: Number,
    default: 5,
  },
  threadIconSize: {
    type: Number,
    default: 18,
  },
  threadIconColor: {
    type: String,
    default: 'var(--colour-utility-black)',
  },
  threadIconColorSelected: {
    type: String,
    default: 'var(--colour-data-puerto-rico-label)',
  },
});

const emit = defineEmits(['update:selected', 'reply', 'viewMore', 'reload']);
const showViewMore = computed(() => props.threads?.length === props.limit);
const replyToEmail = (thread) => emit('reply', thread);
const handleSelected = (thread) => {
  emit('update:selected', props.selectedId === thread?.thread_id ? undefined : thread);
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.view-more-link {
  font-weight: var(--font-weight-semibold);
}
.scrollable {
  flex: 1;
  min-height: 0;
  padding-right: var(--spacing-half);
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 600px;
}

.no-emails {
  padding: var(--spacing-2);
}
</style>
