<template>
  <div>
    <Field
      :id="props.id"
      required
      :error-message="t('Please choose an area')"
      type="select"
      :options="filterOptions"
      :value="props.value.startsWith('customsection') ? 'customsection' : props.value"
      :label="t('Filter by')"
      @input="emit('input', $event)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Field from '@/shared/components/Form/Field.vue';
import { t } from '@sales-i/utils';

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  value: {
    type: String,
    default: ''
  },
});

const emit = defineEmits(['input']);

const filterOptions = computed(() => [
  {
    text: t('Interactions'),
    value: 'interaction',
  },
  {
    text: t('Contacts'),
    value: 'contact',
  },
  {
    text: t('Location'),
    value: 'address',
  },
  {
    text: t('Custom Schema'),
    value: 'customsection',
  },
].filter(Boolean));
</script>