<template>
  <div class="opportunities-title-container bg-blue-light">
    <h2>{{ t('Opportunities') }}</h2>
    <h4>{{ t('Recommended products for your customer') }}</h4>
    <CustomButton
      small
      purpose="action"
      class="cta mt-1"
      @click="addOpportunityModal"
    >
      {{ t('Add Opportunity') }}
    </CustomButton>
  </div>
  <Tabs
    force-same-view
    :items="tabs"
    :selected-index="tabSelectedIndex"
    @nav-clicked="navClicked"
  />
  <BufferImage
    v-if="opportunitiesLoading"
    class="buffer"
    color="var(--colour-utility-black)"
    float="center"
  />
  <div
    v-else
    class="table-wrapper"
  >
    <EntityListViewTable
      entity="opportunity"
      :items="opportunityItems"
      :headings="opportunityHeadingsCustomerView"
      :status="status"
      :sort-method="opportunitySortMethod"
      :sort-attribute="opportunitySortAttribute"
      @edit="editOpportunity"
      @opportunity-deleted="handleOpportunityDeleted"
      @sort="sortOpportunities"
    />
  </div>
  <BufferImage
    v-if="aiOpportunitiesLoading"
    class="buffer"
    color="var(--colour-utility-black)"
    float="center"
  />
  <div
    v-else-if="status === 'open'"
    class="table-wrapper"
  >
    <Accordion
      class="accordion-opportunities"
      :title="`${t('AI generated opportunities')} (${aiOpportunityItems.length})`"
      :is-opened="isAccordionOpened"
      accordion-size="small"
    >
      <template #content>
        <EntityListViewTable
          class="entity-list-view-table"
          entity="opportunity"
          status="potential"
          :items="aiOpportunityItems"
          :headings="aiOpportunityHeadingsCustomerView"
          :sort-method="aiOpportunitySortMethod"
          :sort-attribute="aiOpportunitySortAttribute"
          @open-modal="openProductsModal"
          @opportunity-deleted="handleOpportunityDeleted"
          @sort="sortOpportunities"
        />
      </template>
    </Accordion>
  </div>

  <MissedPotentialOpportunityModal
    v-if="selectedOpportunity"
    :selected-opportunity="selectedOpportunity"
    :dismissed-items="dismissedItems"
    :modal-open="modalOpen"
    @close-modal="closeProductsModal"
    @add-feedback="showDismissModal"
  />

  <DismissModal
    :dismiss-modal-open="dismissModalOpen"
    :close-dismiss-modal="closeDismissModal"
    :dismiss-options="dismissOptions"
    object-type="opportunity"
    @post-feedback="postFeedback"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { CustomButton, BufferImage } from '@sales-i/dsv3';
import { dates, t } from '@sales-i/utils';
import usePermissions from '@/shared/composables/usePermissions';
import { opportunities } from '@/shared/store/data/policies';
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import EntityListViewTable from '@/shared/components/Tables/EntityListViewTable';
import MissedPotentialOpportunityModal from '@/crm/components/Opportunity/MissedPotentialOpportunityModal.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import { useRoute, } from 'vue-router';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { DateTime } from 'luxon';
import Accordion from '@/shared/components/Accordion/Accordion';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { useOpportunityProductsModal } from '@/crm/composables/useOpportunityProductsModal';
import { REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';

const { getPermissions } = usePermissions();
const permissionsCRM = getPermissions(opportunities);
const vroute = useRoute();

const props = defineProps({
  entityType: {
    type: String,
    default: 'customer',
  },
});

const { 
  getOpportunities, 
  getOpportunitiesWithProducts, 
  clearOpportunities,
  clearAiOpportunities,
  setRoute, 
  getSummary,
  editOpportunity,
  opportunitiesLoading,
  opportunitiesLoaded,
  opportunityItems,
  opportunitiesSummary,
  aiOpportunityItems,
  aiOpportunityHeadingsCustomerView, 
  aiOpportunitiesLoading,
  opportunityHeadingsCustomerView,
} = useOpportunity();

const {
  openProductsModal,
  closeProductsModal,
  closeDismissModal,
  showDismissModal,
  postFeedback,
  modalOpen,
  dismissModalOpen,
  dismissedItems,
  dismissOptions,
  selectedOpportunity,
} = useOpportunityProductsModal(loadData);

const aiOpportunitySortMethod = ref(REPORTS_SORT_DESCENDING_KEY);
const aiOpportunitySortAttribute = ref('value');
const opportunitySortMethod = ref(REPORTS_SORT_DESCENDING_KEY);
const opportunitySortAttribute = ref('value');
const tabSelectedIndex = ref(0);
const isAccordionOpened = ref(true);
const status = ref('open');
const subjectId = computed(() => vroute.params?.id);

const tabs = computed(() => [
  ...(permissionsCRM.read
    ? [
      {
        title: t('Open'),
        query: 'open',
        amount: opportunitiesSummary.value?.summary_overview?.opportunity_count_open,
      },
      {
        title: t('Won'),
        query: 'won',
      },
      {
        title: t('Lost'),
        query: 'lost',
      },
    ]
    : []),
]);

onMounted(() => { 
  loadData();
  getOpportunitiesSummary();
});

function navClicked(data) {
  status.value = data.item.query;
  tabSelectedIndex.value = data.index;
  loadData();
}

function addOpportunityModal() {
  setRoute({ cancel: vroute.fullPath, });
  const path = `${baseUrl}/${opportunitiesArea}/add`;
  const query = new URLSearchParams({ 
    status: 'open',
    entityId: subjectId.value,
    entityPreselected: true,
    entityType: props.entityType,
  });
  navigateToUrl(`${path}?${query.toString()}`);
}

async function handleOpportunityDeleted() {
  await loadData();
  await getOpportunitiesSummary();
}

async function loadData() {
  // cancel reload of opportunities if add/edit button pressed
  // only for main opportunities page
  if (['AddOpportunityModalRoute', 'EditOpportunityModalRoute'].includes(vroute.name) && (opportunitiesLoaded.value || opportunitiesLoading.value)) {
    return;
  }
  await loadOpportunities();
  if (status.value === 'open') {
    await loadAiOpportunities();
  }
}

async function loadOpportunities(sort = 'value:desc') {
  await clearOpportunities();
  await getOpportunities({
    type: 'manual',
    status: status.value,
    sort,
    limit: 50,
    offset: 0,
    entity_id: subjectId.value,
    date_from: dates.forApi(DateTime.local(), true),
    date_to: dates.forApi(DateTime.local().plus({ days: 90 }), true),
    days: 31,
  });
}

async function loadAiOpportunities(sort = 'value:desc') {
  await clearAiOpportunities();
  await getOpportunitiesWithProducts({
    type: ['MISSED', 'POTENTIAL'],
    sort,
    limit: 50,
    offset: 0,
    entity_id: subjectId.value,
  });
}

function sortOpportunities({ newSortMethod, newSortAttribute, status }) {
  const sort = `${newSortAttribute}:${newSortMethod}`;
  if (['missed', 'potential'].includes(status)) {
    aiOpportunitySortMethod.value = newSortMethod;
    aiOpportunitySortAttribute.value = newSortAttribute;
    loadAiOpportunities(sort);
  } else {
    opportunitySortMethod.value = newSortMethod;
    opportunitySortAttribute.value = newSortAttribute;
    loadOpportunities(sort);
  }
}

async function getOpportunitiesSummary() {
  getSummary({
    customer_id: subjectId.value,
    date_from: dates.forApi(DateTime.local(), true),
    date_to: dates.forApi(DateTime.local().plus({ days: 90 }), true),
    date_type: 'decision',
  });
}
</script>

<style scoped lang="scss">
@import '@/shared/assets/scss/_variables';

.table-wrapper {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding: 0 var(--spacing-4);
  }
}

.entity-list-view-table :deep(.table-wrapper) {
  height: 100%;
  overflow: visible;
}

.opportunities-title-container {
  background-color: var(--colour-panel-alert-base);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
  position: relative;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: var(--spacing-3);
    margin: 0 0 var(--spacing-4) 0;
  }

  h4 {
    font-weight: var(--font-weight-regular);
  }

  .cta {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      position: absolute;
      right: var(--spacing-5);
    }
  }
}

.accordion-opportunities {
  margin-top: var(--spacing-1);
  box-shadow: none;

  :deep(.heading) {
    justify-content: center;
  }

  :deep(.heading h3) {
    color: var(--colour-utility-black);
    text-decoration: none;
  }

  :deep(.heading .svg-container svg) {
    color: var(--colour-utility-black) !important;
  }
}
</style>