import { registerApplication, start } from 'single-spa';
import {
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import {
  getAccountInfo,
  getCurrency,
  getUserDetails,
  getTranslations,
  getTenantDetails,
  getPermissions,
} from '@sales-i/utils';

// Define the routes based on the layout file
const routes = constructRoutes(microfrontendLayout);

// Custom function to check if the path is /login, /logout, or /masquerading
function isLoginLogoutOrMasquerading(location) {
  return ['/login', '/logout', '/masquerading'].includes(location.pathname);
}

// Hardcoded applications based on authentication status
const applications = [
  {
    name: '@sales-i/auth',
    app: () => System.import('@sales-i/auth'),
    activeWhen: () => true,
  },
  {
    name: '@sales-i/header',
    app: () => System.import('@sales-i/header'),
    activeWhen: () => true,
  },
  {
    name: '@sales-i/breadcrumbs',
    app: () => System.import('@sales-i/breadcrumbs'),
    activeWhen: () => true,
  },
  {
    name: '@sales-i/aiquery',
    app: () => System.import('@sales-i/aiquery'),
    activeWhen: location => !isLoginLogoutOrMasquerading(location),
  },
  {
    name: '@sales-i/admin',
    app: () => System.import('@sales-i/admin'),
    activeWhen: location => location.pathname.startsWith('/admin') && !isLoginLogoutOrMasquerading(location),
  },
  {
    name: '@sales-i/crm',
    app: () => System.import('@sales-i/crm'),
    activeWhen: location => !location.pathname.startsWith('/admin') && !isLoginLogoutOrMasquerading(location),
  },
];

// Initialize and activate layout engine
const layoutEngine = constructLayoutEngine({ routes, applications });

// Register applications and start the layout engine
applications.forEach(app => registerApplication(app));
layoutEngine.activate();

// Fetch user data and other initial app-wide settings
getUserDetails().then(() => {
  getCurrency();
  getTranslations({ locale: localStorage.getItem('locale') || 'en-gb' });
  getTenantDetails();
  getPermissions();
  getAccountInfo();
}).catch((e) => {
  console.error('Failed to fetch user details', e);
});  

start();
